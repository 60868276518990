// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; 
}

.dialog-err {
    display: flex;
    flex-direction: column;

    background-color: var(--color--red--bright);
    color: var(--color--white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 1em;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    max-width: 50%;  
    z-index: 1000;
  }

  .dialog-err-ok {
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .dialog-err-ok p {
    font-size: 1.4em;
    padding: 0.4em;
    margin: 0;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
    color: var(--color--white);
    background-color: var(--color--red--dark);
  }

  .dialog-err-ok a {
    text-decoration: none;
    color: var(--color--white);
  }

  .dialog-err-ok p:hover {
    cursor: pointer;
    background-color: var(--color--white);
    color: var(--color--black);
  }

  .dialog-err-ok a:hover {
    cursor: pointer;
    background-color: var(--color--white);
    color: var(--color--black);
  }
`, "",{"version":3,"sources":["webpack://./src/components/DialogErr.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;;IAEtB,2CAA2C;IAC3C,0BAA0B;IAC1B,0CAA0C;IAC1C,mBAAmB;IACnB,YAAY;;IAEZ,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;;IAEhC,cAAc;IACd,aAAa;EACf;;EAEA;IACE,uBAAkB;IAAlB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;IACrB,0BAA0B;IAC1B,yCAAyC;EAC3C;;EAEA;IACE,qBAAqB;IACrB,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,qCAAqC;IACrC,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,qCAAqC;IACrC,0BAA0B;EAC5B","sourcesContent":[".dialog {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 999; \n}\n\n.dialog-err {\n    display: flex;\n    flex-direction: column;\n\n    background-color: var(--color--red--bright);\n    color: var(--color--white);\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);\n    border-radius: 15px;\n    padding: 1em;\n\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    max-width: 50%;  \n    z-index: 1000;\n  }\n\n  .dialog-err-ok {\n    width: fit-content;\n  }\n  \n  .dialog-err-ok p {\n    font-size: 1.4em;\n    padding: 0.4em;\n    margin: 0;\n    border-radius: 5px;\n    font-weight: bold;\n    text-decoration: none;\n    color: var(--color--white);\n    background-color: var(--color--red--dark);\n  }\n\n  .dialog-err-ok a {\n    text-decoration: none;\n    color: var(--color--white);\n  }\n\n  .dialog-err-ok p:hover {\n    cursor: pointer;\n    background-color: var(--color--white);\n    color: var(--color--black);\n  }\n\n  .dialog-err-ok a:hover {\n    cursor: pointer;\n    background-color: var(--color--white);\n    color: var(--color--black);\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
