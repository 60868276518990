// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cinema-detail-halls {
    display: flex;
    flex-direction: column;
}

.cinema-detail-halls-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
}
.cinema-detail-halls-header p {
    padding: .5em;
    background-color: var(--color--red--bright);
    color: var(--color--white);
    border-radius: .5em;
    cursor: pointer;
    transition-duration: var(--transition-delay--long);
}
.cinema-detail-halls-header p:hover {
    background-color: var(--color--red--dark);
}
.cinema-detail-halls-body {
    overflow: auto;
    max-height: 40vh;
    background-color: white;
    border-radius: .5em;
} `, "",{"version":3,"sources":["webpack://./src/pages/managment/details/CinemaDetail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,aAAa;IACb,2CAA2C;IAC3C,0BAA0B;IAC1B,mBAAmB;IACnB,eAAe;IACf,kDAAkD;AACtD;AACA;IACI,yCAAyC;AAC7C;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".cinema-detail-halls {\n    display: flex;\n    flex-direction: column;\n}\n\n.cinema-detail-halls-header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 1em;\n}\n.cinema-detail-halls-header p {\n    padding: .5em;\n    background-color: var(--color--red--bright);\n    color: var(--color--white);\n    border-radius: .5em;\n    cursor: pointer;\n    transition-duration: var(--transition-delay--long);\n}\n.cinema-detail-halls-header p:hover {\n    background-color: var(--color--red--dark);\n}\n.cinema-detail-halls-body {\n    overflow: auto;\n    max-height: 40vh;\n    background-color: white;\n    border-radius: .5em;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
