// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.management {
    display: flex;
    flex-direction: row;
    width: 70%;
    align-self: center;
    align-items: center;

    margin: 10vh 0;
}

.management-referations {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

@media only screen and (max-width: 450px) {
    .management {
        margin: 0;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/managment/Management.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,kBAAkB;IAClB,mBAAmB;;IAEnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI;QACI,SAAS;QACT,WAAW;IACf;AACJ","sourcesContent":[".management {\n    display: flex;\n    flex-direction: row;\n    width: 70%;\n    align-self: center;\n    align-items: center;\n\n    margin: 10vh 0;\n}\n\n.management-referations {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    flex-wrap: wrap;\n}\n\n@media only screen and (max-width: 450px) {\n    .management {\n        margin: 0;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
