// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pw-reset {
    display: flex;
    margin: auto;
    margin-top: 10%;

    justify-content: center;
    align-items: center;
    
    flex-direction: column;
}

.pw-reset-view {
    background-color: var(--color--grey--lightest);
    padding: 3em 1.5em .2em 1.5em;
    border-radius: 15px;
    width: min-content;
}

.pw-reset-none {
    display: none;
}

.pw-reset input {
    border: none;
    font-size: 1.5em;
    padding: .5em;
    outline: none;
}

.pw-reset input {
    border-bottom: 2px var(--color--black) solid;
    padding-bottom: .2em;
}

.pw-reset-btns {
    display: flex;
    justify-content: space-between;
}

.pw-reset-btns > button, .pw-reset-btns > a {
    margin: .5em;
    margin-top: 1em;
    font-size: 1.3em;
    padding: .7em;
    border: none;
    text-decoration: none;
    border-radius: 5px;
    background-color: var(--color--red--bright);
    color: var(--color--white);
    transition-duration: var(--transition-delay--long);
    cursor: pointer;
}

.pw-reset-btns *:hover {
    background-color: var(--color--red--dark);
}
.pw-reset-err {
    color: var(--color--red--bright);
}`, "",{"version":3,"sources":["webpack://./src/pages/login/PwReset.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,eAAe;;IAEf,uBAAuB;IACvB,mBAAmB;;IAEnB,sBAAsB;AAC1B;;AAEA;IACI,8CAA8C;IAC9C,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,4CAA4C;IAC5C,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,2CAA2C;IAC3C,0BAA0B;IAC1B,kDAAkD;IAClD,eAAe;AACnB;;AAEA;IACI,yCAAyC;AAC7C;AACA;IACI,gCAAgC;AACpC","sourcesContent":[".pw-reset {\n    display: flex;\n    margin: auto;\n    margin-top: 10%;\n\n    justify-content: center;\n    align-items: center;\n    \n    flex-direction: column;\n}\n\n.pw-reset-view {\n    background-color: var(--color--grey--lightest);\n    padding: 3em 1.5em .2em 1.5em;\n    border-radius: 15px;\n    width: min-content;\n}\n\n.pw-reset-none {\n    display: none;\n}\n\n.pw-reset input {\n    border: none;\n    font-size: 1.5em;\n    padding: .5em;\n    outline: none;\n}\n\n.pw-reset input {\n    border-bottom: 2px var(--color--black) solid;\n    padding-bottom: .2em;\n}\n\n.pw-reset-btns {\n    display: flex;\n    justify-content: space-between;\n}\n\n.pw-reset-btns > button, .pw-reset-btns > a {\n    margin: .5em;\n    margin-top: 1em;\n    font-size: 1.3em;\n    padding: .7em;\n    border: none;\n    text-decoration: none;\n    border-radius: 5px;\n    background-color: var(--color--red--bright);\n    color: var(--color--white);\n    transition-duration: var(--transition-delay--long);\n    cursor: pointer;\n}\n\n.pw-reset-btns *:hover {\n    background-color: var(--color--red--dark);\n}\n.pw-reset-err {\n    color: var(--color--red--bright);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
