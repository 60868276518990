// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.management-referations {
    width: 100%;
}
.management-reference {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color--red--bright);
    padding: 4%;
    margin: 2%;
    border-radius: 1em;
    min-width: 15em;

    transition-duration: var(--transition-delay--short);
}

.management-reference:hover {
    cursor: pointer;
    box-shadow: 1em 1em 2em var(--color--grey--medium);
}

.management-reference p {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--color--grey--light);
}

.management-reference-img {
    /*background: url('../../assets/imgs/favicons/projection-favikon.png');*/
    background-size: cover;
    width: 100px;
    height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/components/management/ManagementReferation.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,2CAA2C;IAC3C,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,eAAe;;IAEf,mDAAmD;AACvD;;AAEA;IACI,eAAe;IACf,kDAAkD;AACtD;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,wEAAwE;IACxE,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".management-referations {\n    width: 100%;\n}\n.management-reference {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: var(--color--red--bright);\n    padding: 4%;\n    margin: 2%;\n    border-radius: 1em;\n    min-width: 15em;\n\n    transition-duration: var(--transition-delay--short);\n}\n\n.management-reference:hover {\n    cursor: pointer;\n    box-shadow: 1em 1em 2em var(--color--grey--medium);\n}\n\n.management-reference p {\n    font-size: 1.2em;\n    font-weight: bold;\n    color: var(--color--grey--light);\n}\n\n.management-reference-img {\n    /*background: url('../../assets/imgs/favicons/projection-favikon.png');*/\n    background-size: cover;\n    width: 100px;\n    height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
