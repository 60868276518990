// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hall-record {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5%;
    padding-left: 5%;
}
.hall-record:hover {
    background-color: var(--color--grey--lightest);
}
.hall-record p {
    font-size: 1.5em;
    cursor: pointer;
}

.hall-record button {
    background-color: var(--color--red--bright);
    color: white;
    padding: .5em;
    box-shadow: none;
    border: none;
    border-radius: .5em;
    transition-duration: var(--transition-delay--long);
    cursor: pointer;
}

.hall-record-dizabled {
    opacity: .5;
    cursor: not-allowed !important;
}`, "",{"version":3,"sources":["webpack://./src/components/management/cinemaDetail/HallRecord.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,8CAA8C;AAClD;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,2CAA2C;IAC3C,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,kDAAkD;IAClD,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,8BAA8B;AAClC","sourcesContent":[".hall-record {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-right: 5%;\n    padding-left: 5%;\n}\n.hall-record:hover {\n    background-color: var(--color--grey--lightest);\n}\n.hall-record p {\n    font-size: 1.5em;\n    cursor: pointer;\n}\n\n.hall-record button {\n    background-color: var(--color--red--bright);\n    color: white;\n    padding: .5em;\n    box-shadow: none;\n    border: none;\n    border-radius: .5em;\n    transition-duration: var(--transition-delay--long);\n    cursor: pointer;\n}\n\n.hall-record-dizabled {\n    opacity: .5;\n    cursor: not-allowed !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
