// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.film-detail-checkbox input {
    width: 1em;
    height: 1em;
}
.film-detail-checkbox label {
    border: none;
    font-size: 1.5em;
    padding: .5em;
    outline: none;
}
.film-detail-checkbox {
    display: flex;
    align-items: center;
    margin-top: .7em;
}

.film-detail-multiple {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 0;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1vh;
}
.film-detail-multiple input[type="button"] {
    text-align: center;
    border-bottom: none;
    height: min-content;
    padding: .2em .4em;
    margin-left: 10px;
}
.film-detail-selects {
    margin-top: 1.5em;
}

.film-detail-textarea {
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/managment/details/FilmDetail.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".film-detail-checkbox input {\n    width: 1em;\n    height: 1em;\n}\n.film-detail-checkbox label {\n    border: none;\n    font-size: 1.5em;\n    padding: .5em;\n    outline: none;\n}\n.film-detail-checkbox {\n    display: flex;\n    align-items: center;\n    margin-top: .7em;\n}\n\n.film-detail-multiple {\n    display: flex;\n    justify-content: space-between;\n    position: relative;\n    z-index: 0;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 1vh;\n}\n.film-detail-multiple input[type=\"button\"] {\n    text-align: center;\n    border-bottom: none;\n    height: min-content;\n    padding: .2em .4em;\n    margin-left: 10px;\n}\n.film-detail-selects {\n    margin-top: 1.5em;\n}\n\n.film-detail-textarea {\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
