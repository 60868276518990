// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-detail-permisions {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow: auto;
}

.role-detail-permisions-permission {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding-right: 30%;
}
.role-detail-permisions-permission p {
    font-size: 1.5em;
    padding: .5em;
}

.role-detail-permisions-permission:hover {
    background-color: var(--color--grey--medium);
}

.role-detail-permisions-permission input, .role-detail-permisions-permission p {
    margin: .2em;
}

.role-detail-permisions-permission input {
    width: 1em;
    height: 1em;
}`, "",{"version":3,"sources":["webpack://./src/pages/managment/details/RoleDetail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,WAAW;AACf","sourcesContent":[".role-detail-permisions {\n    display: flex;\n    flex-direction: column;\n    max-height: 50vh;\n    overflow: auto;\n}\n\n.role-detail-permisions-permission {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    margin: 0;\n    padding-right: 30%;\n}\n.role-detail-permisions-permission p {\n    font-size: 1.5em;\n    padding: .5em;\n}\n\n.role-detail-permisions-permission:hover {\n    background-color: var(--color--grey--medium);\n}\n\n.role-detail-permisions-permission input, .role-detail-permisions-permission p {\n    margin: .2em;\n}\n\n.role-detail-permisions-permission input {\n    width: 1em;\n    height: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
