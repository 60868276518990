// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0000002d;
    z-index: 71;
  }
  
  .react-spinner-loader {
    width: 100px;
    height: 100px;
  }`, "",{"version":3,"sources":["webpack://./src/LoadingSpiner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,aAAa;EACf","sourcesContent":[".loading-spinner {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    background-color: #0000002d;\n    z-index: 71;\n  }\n  \n  .react-spinner-loader {\n    width: 100px;\n    height: 100px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
