// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .25em .5em;
    border: 1px solid var(--color--grey--light);
    border-radius: 1em;

    font-size: 1.3em;
    font-weight: bold;
    background-color: white;
    color: black;
}

.payment-option img {
    height: 5em;
}

.payment-option > * {
    margin: 0 .5em;
}

.payment-option > input {
    height: 1.5em;
    width: 1.5em;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .payment-option {
        margin: 0 .5em;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/filmDetail/PaymentOption.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;;IAElB,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".payment-option {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: .25em .5em;\n    border: 1px solid var(--color--grey--light);\n    border-radius: 1em;\n\n    font-size: 1.3em;\n    font-weight: bold;\n    background-color: white;\n    color: black;\n}\n\n.payment-option img {\n    height: 5em;\n}\n\n.payment-option > * {\n    margin: 0 .5em;\n}\n\n.payment-option > input {\n    height: 1.5em;\n    width: 1.5em;\n    cursor: pointer;\n}\n\n@media only screen and (max-width: 600px) {\n    .payment-option {\n        margin: 0 .5em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
