// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter label {
    font-size: 2em;
    margin-right: .5em;
}
.filter > input {
    border-radius: .5em;
    border: none;
    padding: .5em;
}

@media only screen and (max-width: 450px) {
    .filter {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .filter > * {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 400px) {
    .filter input {
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/management/Filter.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,uBAAuB;IAC3B;IACA;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".filter label {\n    font-size: 2em;\n    margin-right: .5em;\n}\n.filter > input {\n    border-radius: .5em;\n    border: none;\n    padding: .5em;\n}\n\n@media only screen and (max-width: 450px) {\n    .filter {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n    }\n    .filter > * {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n}\n\n@media only screen and (max-width: 400px) {\n    .filter input {\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
