// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reservation-group > div:first-child {
    display: flex;
    justify-content: space-around;
    align-items: center;

    background-color: white;
    padding: 1.5em 0;
    margin: 0;
    margin-top: 3em;
    margin-bottom: 1em;

    border-bottom: 1px solid var(--color--grey--medium);
    box-shadow: .5em .5em .5em 0em var(--color--grey--light);

    border-radius: .5em;
}

.reservation-group > div:first-child > p {
    font-size: 1.3em;
    font-weight: bold;
}

.reservation-group-content {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 400px) {
    .reservation-group-header {
        display: flex;
        flex-wrap: wrap;
    }
    .reservation-group-header h2 {
        font-size: 1.7em;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/myReservations/ReservationGroup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;;IAEnB,uBAAuB;IACvB,gBAAgB;IAChB,SAAS;IACT,eAAe;IACf,kBAAkB;;IAElB,mDAAmD;IACnD,wDAAwD;;IAExD,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;;IAEb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;QACI,aAAa;QACb,eAAe;IACnB;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".reservation-group > div:first-child {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n\n    background-color: white;\n    padding: 1.5em 0;\n    margin: 0;\n    margin-top: 3em;\n    margin-bottom: 1em;\n\n    border-bottom: 1px solid var(--color--grey--medium);\n    box-shadow: .5em .5em .5em 0em var(--color--grey--light);\n\n    border-radius: .5em;\n}\n\n.reservation-group > div:first-child > p {\n    font-size: 1.3em;\n    font-weight: bold;\n}\n\n.reservation-group-content {\n    display: flex;\n\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n@media only screen and (max-width: 400px) {\n    .reservation-group-header {\n        display: flex;\n        flex-wrap: wrap;\n    }\n    .reservation-group-header h2 {\n        font-size: 1.7em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
