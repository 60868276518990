// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trust-form {
    width: 20%;
    position: absolute;
    z-index: 55;
    background-color: white;
}

.trust-form > div {
    text-align: center;
    border-radius: .2em;
    padding: .1em;
    padding-bottom: 0;
    background-color: #ff000010;
}

.device-trust-form {
    display: flex;
    flex-direction: column;
    background-color: var(--color--grey--lightest);
    padding: 2em;
    border-radius: .5em;
    background-color: #ff000010;
}

.device-trust-form button {
    font-size: 1.3em;
    padding: .7em;
    width: min-content;
    border: none;
    border-radius: 5px;
    background-color: var(--color--red--bright);
    color: var(--color--white);
    transition-duration: var(--transition-delay--long);
}

.device-trust-form button:hover {
    background-color: var(--color--red--dark);
    cursor: pointer;
}

.device-trust-confirm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/accessing/DeviceTrust.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8CAA8C;IAC9C,YAAY;IACZ,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,2CAA2C;IAC3C,0BAA0B;IAC1B,kDAAkD;AACtD;;AAEA;IACI,yCAAyC;IACzC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".trust-form {\n    width: 20%;\n    position: absolute;\n    z-index: 55;\n    background-color: white;\n}\n\n.trust-form > div {\n    text-align: center;\n    border-radius: .2em;\n    padding: .1em;\n    padding-bottom: 0;\n    background-color: #ff000010;\n}\n\n.device-trust-form {\n    display: flex;\n    flex-direction: column;\n    background-color: var(--color--grey--lightest);\n    padding: 2em;\n    border-radius: .5em;\n    background-color: #ff000010;\n}\n\n.device-trust-form button {\n    font-size: 1.3em;\n    padding: .7em;\n    width: min-content;\n    border: none;\n    border-radius: 5px;\n    background-color: var(--color--red--bright);\n    color: var(--color--white);\n    transition-duration: var(--transition-delay--long);\n}\n\n.device-trust-form button:hover {\n    background-color: var(--color--red--dark);\n    cursor: pointer;\n}\n\n.device-trust-confirm {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
