// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smart-input-big-label, .smart-input {
    display: flex;
    flex-direction: column;
    padding: .5em;
    margin: .5em 0;
    background-color: white;
    border-radius: .5em;
    height: min-content ;
    width: auto;
}
.smart-input-big-label > label, .smart-input > label  {
    height: 0;
}

.smart-input-big-label > label { 
    position: relative;
    top: .8em;
    left: .5em;
    font-size: 1.5em;
    transition-duration: var(--transition-delay--long);
}

.smart-input > label {
    position: relative;
    top: 0;
    left: 0;
    font-size: 1em;
    transition-duration: var(--transition-delay--long);
}
.smart-input-big-label > input, .smart-input > input {
    font-size: 1.5em;
    margin-top: .7em;
}`, "",{"version":3,"sources":["webpack://./src/components/SmartInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,cAAc;IACd,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB,WAAW;AACf;AACA;IACI,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,kDAAkD;AACtD;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,cAAc;IACd,kDAAkD;AACtD;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".smart-input-big-label, .smart-input {\n    display: flex;\n    flex-direction: column;\n    padding: .5em;\n    margin: .5em 0;\n    background-color: white;\n    border-radius: .5em;\n    height: min-content ;\n    width: auto;\n}\n.smart-input-big-label > label, .smart-input > label  {\n    height: 0;\n}\n\n.smart-input-big-label > label { \n    position: relative;\n    top: .8em;\n    left: .5em;\n    font-size: 1.5em;\n    transition-duration: var(--transition-delay--long);\n}\n\n.smart-input > label {\n    position: relative;\n    top: 0;\n    left: 0;\n    font-size: 1em;\n    transition-duration: var(--transition-delay--long);\n}\n.smart-input-big-label > input, .smart-input > input {\n    font-size: 1.5em;\n    margin-top: .7em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
