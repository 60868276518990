// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visa-payment {
    color: black;
}

.visa-payment > div {
    margin-top: 2em;
}

.visa-payment input {
    border: none;
    border-bottom: 2px black solid;
    padding: .5em;
    margin: .3em 0;
}

.visa-payment input:focus {
    outline: none;
}

@media only screen and (max-width: 600px) {
    .visa-payment {
        margin: 0 .5em;
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/filmDetail/payments/VisaPayment.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;QACd,kBAAkB;IACtB;AACJ","sourcesContent":[".visa-payment {\n    color: black;\n}\n\n.visa-payment > div {\n    margin-top: 2em;\n}\n\n.visa-payment input {\n    border: none;\n    border-bottom: 2px black solid;\n    padding: .5em;\n    margin: .3em 0;\n}\n\n.visa-payment input:focus {\n    outline: none;\n}\n\n@media only screen and (max-width: 600px) {\n    .visa-payment {\n        margin: 0 .5em;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
