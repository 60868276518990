// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -moz-fit-content;
    width: fit-content;
    
    height: 100%;
    padding: 1em;
    margin: 1em ;
    border-radius: .5em;
    background-color: var(--color--grey--light);
    transition-duration: var(--transition-delay--long);
    cursor: pointer;
}

.tile:hover {
    box-shadow: .5em .5em .5em var(--color--grey--medium);
}

.tile-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.tile-header > h2 {
    margin: 0 1em;
    margin-left: 0;
}

.tile-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.tile-body > * {
    padding: .2em;
    margin: .2em;
}

.tile-actions {
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .tile-header {

    }
}`, "",{"version":3,"sources":["webpack://./src/components/management/Tile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAkB;IAAlB,kBAAkB;;IAElB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,2CAA2C;IAC3C,kDAAkD;IAClD,eAAe;AACnB;;AAEA;IACI,qDAAqD;AACzD;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;AAC/B;AACA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;;IAEA;AACJ","sourcesContent":[".tile {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: fit-content;\n    \n    height: 100%;\n    padding: 1em;\n    margin: 1em ;\n    border-radius: .5em;\n    background-color: var(--color--grey--light);\n    transition-duration: var(--transition-delay--long);\n    cursor: pointer;\n}\n\n.tile:hover {\n    box-shadow: .5em .5em .5em var(--color--grey--medium);\n}\n\n.tile-header {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    flex-wrap: wrap;\n}\n.tile-header > h2 {\n    margin: 0 1em;\n    margin-left: 0;\n}\n\n.tile-body {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n}\n.tile-body > * {\n    padding: .2em;\n    margin: .2em;\n}\n\n.tile-actions {\n    align-items: center;\n    justify-content: center;\n}\n\n@media only screen and (max-width: 800px) {\n    .tile-header {\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
