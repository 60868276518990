// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 80vh;
}

@media screen and (max-height: 600px) {
    .login {
        display: flex;
        justify-content: space-around;
        align-items: baseline;
        width: 100%;
        height: 80vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,6BAA6B;QAC7B,qBAAqB;QACrB,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".login {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    width: 100%;\n    height: 80vh;\n}\n\n@media screen and (max-height: 600px) {\n    .login {\n        display: flex;\n        justify-content: space-around;\n        align-items: baseline;\n        width: 100%;\n        height: 80vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
