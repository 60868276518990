// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trailer {
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    position: fixed;
    top: 0;
    left: 0;

    margin: 0;
    width: 100vw;
    height: 100vh;
    
    background-color: #00000090;
}
.trailer > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1%;
    background-color: #000000;
    padding-bottom: 1%;
    min-width: 80%;
    min-height: 80%;
    max-width: 100%;
    max-height: 100%;
}

.trailer p {
    color: var(--color--red--bright);
    position: sticky;
    font-size: 2.5em;
    margin: 0;
    padding-right: 2%;
    cursor: pointer;
    height: 1.5em;
    text-align: end;
}

.trailer iframe {
    min-width: 90%;
    min-height: 80%;
    border: none;
    background-color: #000000;
    padding-bottom: 1%;
}`, "",{"version":3,"sources":["webpack://./src/components/home/Trailer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;;IAEtB,eAAe;IACf,MAAM;IACN,OAAO;;IAEP,SAAS;IACT,YAAY;IACZ,aAAa;;IAEb,2BAA2B;AAC/B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;IACT,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".trailer {\n    z-index: 1000;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    \n    position: fixed;\n    top: 0;\n    left: 0;\n\n    margin: 0;\n    width: 100vw;\n    height: 100vh;\n    \n    background-color: #00000090;\n}\n.trailer > div {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 0 1%;\n    background-color: #000000;\n    padding-bottom: 1%;\n    min-width: 80%;\n    min-height: 80%;\n    max-width: 100%;\n    max-height: 100%;\n}\n\n.trailer p {\n    color: var(--color--red--bright);\n    position: sticky;\n    font-size: 2.5em;\n    margin: 0;\n    padding-right: 2%;\n    cursor: pointer;\n    height: 1.5em;\n    text-align: end;\n}\n\n.trailer iframe {\n    min-width: 90%;\n    min-height: 80%;\n    border: none;\n    background-color: #000000;\n    padding-bottom: 1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
