// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    /*width: 100%;*/
    font-family: Arial, sans-serif;
    z-index: 3;
    overflow: hidden;
  }
  
  .select-input-container input {
    width: 100%;
  }

  .options-list-z-index {
    position: relative;
    z-index: 4;

  }

  .options-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    position: relative;
    background-color: #fff;
    max-height: 10em;
    overflow-y: scroll;
  }
  
  .options-list li {
    position: relative;
    z-index: 5;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .options-list li:hover {
    background-color: #f0f0f0;
  }`, "",{"version":3,"sources":["webpack://./src/components/SelectInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,8BAA8B;IAC9B,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,UAAU;;EAEZ;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,sBAAsB;IACtB,gBAAgB;IAChB,0BAA0B;IAC1B,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".select-input-container {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    /*width: 100%;*/\n    font-family: Arial, sans-serif;\n    z-index: 3;\n    overflow: hidden;\n  }\n  \n  .select-input-container input {\n    width: 100%;\n  }\n\n  .options-list-z-index {\n    position: relative;\n    z-index: 4;\n\n  }\n\n  .options-list {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    border: 1px solid #ccc;\n    border-top: none;\n    border-radius: 0 0 5px 5px;\n    position: relative;\n    background-color: #fff;\n    max-height: 10em;\n    overflow-y: scroll;\n  }\n  \n  .options-list li {\n    position: relative;\n    z-index: 5;\n    padding: 10px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .options-list li:hover {\n    background-color: #f0f0f0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
