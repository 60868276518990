// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projection-group h2 {
    display: flex;
    justify-content: space-around;

    background-color: white;
    padding: 1.5em 0;
    margin: 0;
    /*margin-top: 3em;*/

    border-bottom: 1px solid var(--color--grey--medium);
    box-shadow: .5em .5em .5em 0em var(--color--grey--light);

    margin-bottom: 1em;
}

* >.projection-group:first-child h2 {
    margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/home/ProjectionGroup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;;IAE7B,uBAAuB;IACvB,gBAAgB;IAChB,SAAS;IACT,mBAAmB;;IAEnB,mDAAmD;IACnD,wDAAwD;;IAExD,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".projection-group h2 {\n    display: flex;\n    justify-content: space-around;\n\n    background-color: white;\n    padding: 1.5em 0;\n    margin: 0;\n    /*margin-top: 3em;*/\n\n    border-bottom: 1px solid var(--color--grey--medium);\n    box-shadow: .5em .5em .5em 0em var(--color--grey--light);\n\n    margin-bottom: 1em;\n}\n\n* >.projection-group:first-child h2 {\n    margin-top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
