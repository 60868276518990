// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.beautiful-input {
    display: flex;
    flex-direction: column;
    padding: .5em;
    margin: .5em 0;
    background-color: white;
    border-radius: .5em;
    height: min-content ;
    width: auto;
}
.beautiful-input > label  {
    height: 0;
}

.beautiful-input > label {
    position: relative;
    top: -.4em;
    left: 0;
    z-index: 3;
    font-size: 1em;
    transition-duration: var(--transition-delay--long);
}
.beautiful-input > * {
    margin-top: .7em;
}`, "",{"version":3,"sources":["webpack://./src/components/BeautifulInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,cAAc;IACd,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB,WAAW;AACf;AACA;IACI,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,UAAU;IACV,cAAc;IACd,kDAAkD;AACtD;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".beautiful-input {\n    display: flex;\n    flex-direction: column;\n    padding: .5em;\n    margin: .5em 0;\n    background-color: white;\n    border-radius: .5em;\n    height: min-content ;\n    width: auto;\n}\n.beautiful-input > label  {\n    height: 0;\n}\n\n.beautiful-input > label {\n    position: relative;\n    top: -.4em;\n    left: 0;\n    z-index: 3;\n    font-size: 1em;\n    transition-duration: var(--transition-delay--long);\n}\n.beautiful-input > * {\n    margin-top: .7em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
