// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.film-header-filter-genre {
    display: flex;
    font-size: 1.5em;
    align-items: center;
}

.film-header-filter-genre label {
    font-weight: bold;
    margin-right: 1em;
}

.film-header-filter-genre option, .film-header-filter-genre select {
    font-size: .9em;
}`, "",{"version":3,"sources":["webpack://./src/pages/managment/spreadsheets/FilmsSpreadsheet.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".film-header-filter-genre {\n    display: flex;\n    font-size: 1.5em;\n    align-items: center;\n}\n\n.film-header-filter-genre label {\n    font-weight: bold;\n    margin-right: 1em;\n}\n\n.film-header-filter-genre option, .film-header-filter-genre select {\n    font-size: .9em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
